<template>
  <v-card flat class="rounded-10">
    <v-card-title class="text-center py-5">
      <v-row align="center" justify="center">
        <v-card flat width="180" height="150" v-if="mdAndUp">
          <v-img
            :lazy-src="settings.loader"
            height="100"
            contain
            :src="settings.squareLogo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ settings.SiteName }}
            </h2>
          </v-card-actions>
        </v-card>
        <v-icon x-large class="mx-5" color="primary" v-if="mdAndUp">
          mdi-arrow-left-right-bold
        </v-icon>
        <v-card flat width="180" height="150">
          <v-img
            :lazy-src="settings.loader"
            contain
            height="100"
            :src="connectApp.logo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ connectApp.name }}
            </h2>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-1">
        <p class="text-center secondary--text col-md-8 mx-auto fs-20 py-5">
          Integration with {{ connectApp.name }} will have access to following
          functionality:
        </p>
        <ul class="col-md-6 secondary--text mx-auto fs-17">
          <li>
            Google Drive tab will be accessible from
            <strong>Project HQ > Files > Google Drive</strong>
          </li>
          <li>
            Sync your Google Drive folder with {{ settings.SiteName }} files.
          </li>
          <li>
            Upload files directly to Google Drive (Permissions handle by Google
            Drive)
          </li>
          <li>More features coming soon.</li>
        </ul>
        <v-sheet
          class="text-center secondary--text col-md-8 mx-auto fs-18 py-5"
        >
          <p>
            In order to fully interact with Google Drive API, you will need to
            authorize and give permission to {{ settings.SiteName }}.
          </p>
          <v-img
            contain
            max-height="250"
            :src="require('@/assets/tutorials/google-drive-permission.png')"
          ></v-img>
        </v-sheet>
      </v-row>
      <v-row
        dense
        v-if="googleUser"
        class="py-2 mt-5"
        align="center"
        justify="center"
      >
        <v-col :cols="mdAndUp ? 3 : 12" class="mx-auto">
          <h2 class="fs-20 mb-3 text-center primary--text">
            Connected account
          </h2>
          <div class="fullwidth d-flex align-space-between">
            <v-avatar size="40">
              <v-img
                :lazy-src="settings.loader"
                contain
                :src="googleUser.image"
              ></v-img>
            </v-avatar>
            <div>
              <h2 class="subtitle-2 primary--text ml-2">
                {{ googleUser.name }}
              </h2>
              <h5 class="caption secondary--text ml-2">
                {{ googleUser.email }}
              </h5>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense class="py-3 mt-3" align="center" justify="center">
        <v-hover v-if="!googleUser" v-slot:default="{ hover }">
          <div
            v-if="!googleUser"
            class="cursor-pointer"
            @click.stop="handleSignInGoogle"
            :disabled="!available"
          >
            <v-img
              contain
              height="60"
              :src="
                hover
                  ? require('@/assets/google-signin/btn_google_signin_dark_pressed_web@2x.png')
                  : require('@/assets/google-signin/btn_google_signin_dark_normal_web@2x.png')
              "
            ></v-img>
          </div>
        </v-hover>
        <v-btn
          x-large
          color="error"
          class="text-none px-5"
          v-else
          @click="confirmDisconnect = true"
        >
          Disconnect from {{ connectApp.name }}
        </v-btn>
      </v-row>
    </v-card-text>
    <DeleteDialog
      v-model="confirmDisconnect"
      title="Google Drive disconnection confirmation"
      text-content="Are you sure you want to disconnect this account?."
      @delete="handleSignOutGoogle"
      delete-button-text="Yes, Disconnect account"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteDialog from '@/common/DeleteDialog.vue'
export default {
  components: { DeleteDialog },
  data: () => ({
    isGoogleSignIn: false,
    googleUser: null,
    confirmDisconnect: false
  }),
  computed: {
    ...mapGetters('app', ['connects', 'allowedConnects']),
    connectApp() {
      return this.connects.find((i) => i.id === 'google_drive')
    },
    available() {
      return this.allowedConnects.includes(this.connectApp.id)
    }
  },
  mounted() {
    this.handleClientLoad()
  },
  methods: {
    handleSignInGoogle() {
      if (!this.isGoogleSignIn) {
        this.$gapi
          .signIn()
          .then((user) => {
            this.googleUser = user
            this.isGoogleSignIn = true
            this.appSnackbar('Successfully connected! ', 'success')
          })
          .catch((err) => {
            this.appSnackbar('Error! ' + err.error, 'error')
          })
      }
    },
    handleSignOutGoogle() {
      if (this.isGoogleSignIn) {
        let vm = this
        this.$gapi
          .signOut()
          .then(() => {
            vm.isGoogleSignIn = false
            vm.googleUser = null
            vm.appSnackbar('Successfully disconnected!', 'success')
          })
          .catch((err) => {
            vm.appSnackbar('Error! ' + err.error, 'error')
          })
          .finally(() => this.$router.go())
      }
    },
    handleClientLoad() {
      this.$gapi._load().then((gapi) => {
        this.$gapi.isSignedIn().then((result) => {
          this.isGoogleSignIn = result
          if (result) {
            this.$gapi.currentUser().then((user) => {
              this.googleUser = user
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
